import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import Gallery from '@browniebroke/gatsby-image-gallery';
import { graphql } from 'gatsby';
export const query = graphql`
  query nge {
    images: allFile(
      filter: { relativeDirectory: { eq: "nge-space-odyssey-parallels" } }
      sort: { fields: name }
    ) {
      edges {
        node {
          childImageSharp {
            thumb: gatsbyImageData(layout: CONSTRAINED, width: 270)
            full: gatsbyImageData(layout: FULL_WIDTH)
          }
          name
        }
      }
    }
  }
`;
export const _frontmatter = {
  "title": "NGE and 2001: A Space Odyssey Parallels",
  "date": "2020-08-02T00:00:00.000Z"
};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Gallery images={props.data.images.edges.map(({
      node
    }) => ({
      ...node.childImageSharp,
      caption: node.name,
      thumbAlt: node.name
    }))} mdxType="Gallery" />
    <p>{`• Neon Genesis Evangelion - Deactivation of Jet Alone.`}</p>
    <p>{`• 2001: A Space Odyssey - Deactivation of Hal 9000.`}</p>
    <p>{`A parallel depicts deactivation of two machines. The first from 1985's 'Neon Gensis Evangelion' anime series when Misato Katsuragi was trying to shutdown Jet Alone.
The Seconed was in in 1986's Stanley Kubrick movie '2001: A Space Odessy' when Dave trying to shutdown HAL 9000 machine.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      